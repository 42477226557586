import PropTypes from 'prop-types';
import React from 'react';

export const FlexRow = (props) => {
  let baseStyle = {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'nowrap',
    justifyContent: 'flex-start',
    width: 'auto',
  };

  let newStyle = {...baseStyle, ...props.style};
  return addProps(props, newStyle);
};

FlexRow.propTypes = {
  right: PropTypes.bool,
  center: PropTypes.bool,
  width: PropTypes.number,
  height: PropTypes.number,
  fullWidth: PropTypes.bool,
  halfWidth: PropTypes.bool,
  quarterWidth: PropTypes.bool,
  threeQuarterWidth: PropTypes.bool,
  thirdWidth: PropTypes.bool,
  twoThirdsWidth: PropTypes.bool,
  fifthWidth: PropTypes.bool,
  padding: PropTypes.number,
  padTop: PropTypes.number,
  padBottom: PropTypes.number,
  padLeft: PropTypes.number,
  padRight: PropTypes.number,
  margin: PropTypes.number,
  marginTop: PropTypes.number,
  marginBottom: PropTypes.number,
  marginLeft: PropTypes.number,
  marginRight: PropTypes.number,
  wrap: PropTypes.bool,
  centerBox: PropTypes.bool,
  baseline: PropTypes.bool,
  spaceAround: PropTypes.bool,
  spaceBetween: PropTypes.bool,
  alignCenter: PropTypes.bool,
  alignLeftCenter: PropTypes.bool,
};

export const FlexColumn = (props) => {
  let baseStyle = {
    display: 'flex',
    flexDirection: 'column',
    flexWrap: 'nowrap',
    justifyContent: 'flex-start',
    alignContent: 'flex-start',
    alignItems: 'flex-start',
    alignSelf: 'stretch',
    flexShrink: 0,
  };

  let newStyle = {...baseStyle, ...props.style};
  return addProps(props, newStyle);
};

FlexColumn.propTypes = {
  right: PropTypes.bool,
  center: PropTypes.bool,
  bottom: PropTypes.bool,
  height: PropTypes.number,
  width: PropTypes.node,
  fullWidth: PropTypes.bool,
  halfWidth: PropTypes.bool,
  quarterWidth: PropTypes.bool,
  threeQuarterWidth: PropTypes.bool,
  thirdWidth: PropTypes.bool,
  twoThirdsWidth: PropTypes.bool,
  fifthWidth: PropTypes.bool,
  padding: PropTypes.number,
  padTop: PropTypes.number,
  padBottom: PropTypes.number,
  padLeft: PropTypes.number,
  padRight: PropTypes.number,
  margin: PropTypes.number,
  marginTop: PropTypes.number,
  marginBottom: PropTypes.number,
  marginLeft: PropTypes.number,
  marginRight: PropTypes.number,
  centerBox: PropTypes.bool,
  baseline: PropTypes.bool,
  spaceAround: PropTypes.bool,
  spaceBetween: PropTypes.bool,
};

function addProps(props, newStyle) {
  const {
    right,
    center,
    height,
    width,
    fullWidth,
    halfWidth,
    quarterWidth,
    threeQuarterWidth,
    thirdWidth,
    twoThirdsWidth,
    fifthWidth,
    padding,
    padTop,
    padBottom,
    padLeft,
    padRight,
    margin,
    marginTop,
    marginBottom,
    marginLeft,
    marginRight,
    wrap,
    bottom,
    centerBox,
    baseline,
    spaceAround,
    spaceBetween,
    alignCenter,
    alignLeftCenter,
    style,
    ...other
  } = props;
  if (center) {
    newStyle.justifyContent = 'center';
  } else if (right) {
    newStyle.justifyContent = 'flex-end';
  }
  if (height) {
    newStyle.height = height;
  }
  if (fullWidth) {
    newStyle.width = '100%';
  }
  if (width) {
    newStyle.width = width;
  }
  if (halfWidth) {
    newStyle.width = '50%';
  }
  if (quarterWidth) {
    newStyle.width = '25%';
  }
  if (threeQuarterWidth) {
    newStyle.width = '75%';
  }
  if (thirdWidth) {
    newStyle.width = '33%';
  }
  if (twoThirdsWidth) {
    newStyle.width = '66%';
  }
  if (fifthWidth) {
    newStyle.width = '20%';
  }
  if (padding) {
    newStyle.padding = padding;
  }
  if (padTop) {
    newStyle.paddingTop = padTop;
  }
  if (padBottom) {
    newStyle.paddingBottom = padBottom;
  }
  if (padLeft) {
    newStyle.paddingLeft = padLeft;
  }
  if (padRight) {
    newStyle.paddingRight = padRight;
  }
  if (margin) {
    newStyle.margin = margin;
  }
  if (marginTop) {
    newStyle.marginTop = marginTop;
  }
  if (marginBottom) {
    newStyle.marginBottom = marginBottom;
  }
  if (marginLeft) {
    newStyle.marginLeft = marginLeft;
  }
  if (marginRight) {
    newStyle.marginRight = marginRight;
  }
  if (wrap) {
    newStyle.flexWrap = 'wrap';
  }
  if (bottom) {
    newStyle.justifyContent = 'flex-end';
  }
  if (centerBox) {
    newStyle.justifyContent = 'center';
    newStyle.alignItems = 'center';
    newStyle.height = '100%';
    newStyle.width = '100%';
  }
  if (baseline) {
    newStyle.alignItems = 'baseline';
  }
  if (spaceAround) {
    newStyle.justifyContent = 'space-around';
  }
  if (spaceBetween) {
    newStyle.justifyContent = 'space-between';
  }
  if (alignCenter) {
    newStyle.justifyContent = 'center';
    newStyle.alignItems = 'center';
  }
  if(alignLeftCenter) {
    newStyle.alignItems = 'center';
  }

  return <div {...other} style={newStyle}>{props.children}</div>;
}

export const FlexCenter = (props) => {
  let baseStyle = {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
  };

  let newStyle = {...baseStyle, ...props.style};
  return addProps(props, newStyle);
};

FlexCenter.propTypes = {
  width: PropTypes.number,
  fullWidth: PropTypes.bool,
  halfWidth: PropTypes.bool,
  quarterWidth: PropTypes.bool,
  threeQuarterWidth: PropTypes.bool,
  thirdWidth: PropTypes.bool,
  twoThirdsWidth: PropTypes.bool,
  fifthWidth: PropTypes.bool,
  padding: PropTypes.number,
  padTop: PropTypes.number,
  padBottom: PropTypes.number,
  padLeft: PropTypes.number,
  padRight: PropTypes.number,
  margin: PropTypes.number,
  marginTop: PropTypes.number,
  marginBottom: PropTypes.number,
  marginLeft: PropTypes.number,
  marginRight: PropTypes.number,
};
