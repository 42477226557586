import { Button, Typography } from '@material-ui/core'
import PropTypes from 'prop-types'
import React from 'react'
import { colorPalette } from '../../theme'
import { FlexColumn } from './flexContainers'
import ImageOverlay from './images/ImageOverlay'
import Logo from './Logo'
import HeaderImage from '../images/bg-tools.jpg'
import { Link } from 'gatsby'

const Header = (props) => {
  const {useAltActionButton} = props;
  return (
    <FlexColumn style={{
        width: '100%', 
        maxHeight: 600, 
        overflow: 'hidden', 
        alignItems: 'center',
        backgroundImage: `url(${HeaderImage})`, 
        backgroundPosition: 'center', 
        backgroundSize: 'cover', 
        backgroundRepeat: 'no-repeat',
      }}
    >
      <FlexColumn style={{
          height: '100%', 
          width: '100%', 
          background: 'rgba(0,0,0,.5)', 
          alignItems: 'center', 
          justifyContent: 'center',
          height: 400
        }}
      >
        <Logo/>
        <Typography 
          variant='h4' 
          style={{
            color: 'whitesmoke', 
            textShadow: `2px 2px ${colorPalette.black}`,
            textAlign: 'center',
            marginTop: 50
          }} 
        >
          Handmade gifts, hewn from nature.
        </Typography>
        {/* <Link to={useAltActionButton ? "/" : "/#product-panel"}>
        <Button 
          color='primary' 
          variant='contained' 
          size='large'
          style={{padding: '10px 30px', fontSize: '1.25rem'}}
          // onClick={useAltActionButton ? () => {} : () => {}}
        >
          {useAltActionButton ? 'Go to homepage' : 'Shop'}
        </Button>
        </Link> */}
      </FlexColumn>
    </FlexColumn>
  )
}

Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: ``,
}

export default Header
