import React from 'react';
import PropTypes from 'prop-types';
import { Paper, Typography } from '@material-ui/core';
import { FlexRow } from './flexContainers';
import { colorPalette } from '../../theme';
import withWidth from '@material-ui/core/withWidth';

const Logo = ({style, width}) => {
  
  const fontSize = {
    xs: '5rem',
    sm: '5.5rem',
    md: '7rem',
    lg: '8rem',
    xl: '11rem',  
  };

  const textStyle = {
      fontFamily: 'Allura', 
      textShadow: `4px 2px ${colorPalette.black}`,
      color: 'whitesmoke',
      fontSize: fontSize[width],
      fontWeight: 500,
      lineHeight: .85
  };

  return (
      <Paper
        elevation={0}
        style={{
          width: 'fit-content', 
          backgroundColor: 'transparent',
          ...style,
        }}
      >
        <FlexRow fullWidth wrap alignCenter>
          <Typography 
            variant='h1' 
            style={{
              ...textStyle,
              marginRight: 50, 
            }}
          >
            Peacefield
          </Typography>
          <Typography
            variant='h1' 
            style={textStyle}
          >
            Woodcraft
          </Typography>
        </FlexRow>
      </Paper>
  );
};

Logo.propTypes = {
    
};

export default withWidth()(Logo);