import { CssBaseline, IconButton, Link, Typography } from '@material-ui/core'
import PropTypes from 'prop-types'
import React from 'react'
import Header from './header'
import "@fontsource/allura"
import "@fontsource/josefin-slab"
import { colorPalette } from '../../theme'
import { FlexColumn, FlexRow } from './flexContainers'
import FacebookIcon from '@material-ui/icons/Facebook';
import TwitterIcon from '@material-ui/icons/Twitter';
import PinterestIcon from '@material-ui/icons/Pinterest';
import InstagramIcon from '@material-ui/icons/Instagram';
import YouTubeIcon from '@material-ui/icons/YouTube';

// To add new fonts, run `yarn add @fontsource/font-name` and import here
if (typeof window !== "undefined") {
  // eslint-disable-next-line global-require
  require("smooth-scroll")('a[href*="#"]')
}
const MainLayout = ({ children, useAltActionButton }) => {
  return (
    <div>
      <CssBaseline/>
      <Header useAltActionButton={useAltActionButton} />
      <main>{children}</main>
      <footer style={{ padding: '50px 50px 30px 50px', backgroundColor: colorPalette.black}}>
        <FlexColumn fullWidth style={{color: colorPalette.white, alignItems: 'center', marginBottom: 30}}>
          <Typography variant='body1' style={{color: colorPalette.white}} >
            Find us on social media
          </Typography>
          <FlexRow spaceAround style={{width: '20%'}}>
            <IconButton style={{color: colorPalette.lightLink}} >
            <Link 
                style={{color: colorPalette.lightLink}} 
                target='_blank'  
                href='https://www.youtube.com/channel/UC1Shc_7d8Yq8v2ao9LPqg3Q'
              > 
                <YouTubeIcon />
              </Link>
            </IconButton>
            <IconButton style={{color: colorPalette.lightLink}} >
              <Link 
                style={{color: colorPalette.lightLink}} 
                target='_blank'  
                href='https://www.pinterest.com/peacefieldwoodcraft/'
              > 
                <PinterestIcon />
              </Link>
            </IconButton>
            {/* <IconButton style={{color: colorPalette.lightLink}} >
              <InstagramIcon/>
            </IconButton> */}
            <IconButton style={{color: colorPalette.lightLink}} >
              <Link 
                style={{color: colorPalette.lightLink}} 
                target='_blank'  
                href='https://www.facebook.com/profile.php?id=100068963587688'
              > 
                <FacebookIcon />
              </Link>
              
            </IconButton>
            <IconButton style={{color: colorPalette.lightLink}} >
              <Link style={{color: colorPalette.lightLink}} target='_blank'  href='https://twitter.com/peacewoodcraft'> 
                <TwitterIcon />
              </Link>
            </IconButton>
          </FlexRow>
        </FlexColumn>
        <FlexRow>
          <Typography variant='body2' style={{color: colorPalette.white}} >
            ©{new Date().getFullYear()}, Peacefield Woodcraft. All rights reserved.
          </Typography>
        </FlexRow>
        <Typography variant='caption' style={{color: colorPalette.white}} >
          Photos by{' '}
          <Link style={{color: colorPalette.lightLink}} href="https://unsplash.com/@lighttouchedphotography?utm_source=unsplash&amp;utm_medium=referral&amp;utm_content=creditCopyText">Kevin Schmid</Link>,{' '}
          <Link style={{color: colorPalette.lightLink}} href="https://unsplash.com/@pjswinburn?utm_source=unsplash&amp;utm_medium=referral&amp;utm_content=creditCopyText">Philip Swinburn</Link>,{' '}
          <Link style={{color: colorPalette.lightLink}} href="https://unsplash.com/@dangermonkey?utm_source=unsplash&amp;utm_medium=referral&amp;utm_content=creditCopyText">Justine Meyer</Link>,{' '}
          <Link style={{color: colorPalette.lightLink}} href="https://unsplash.com/@a_lo?utm_source=unsplash&amp;utm_medium=referral&amp;utm_content=creditCopyText">Angela Loria</Link>, and{' '}
          <Link style={{color: colorPalette.lightLink}} href="https://unsplash.com/@fediz?utm_source=unsplash&amp;utm_medium=referral&amp;utm_content=creditCopyText">Federico Izzo</Link> from{' '}
          <Link style={{color: colorPalette.lightLink}} href="https://unsplash.com/">Unsplash</Link>.
        </Typography>
        {' '}
        <Typography variant='caption' style={{color: colorPalette.white}} >
          Icons made by <Link style={{color: colorPalette.lightLink}} href="https://www.freepik.com" title="Freepik">Freepik</Link> from <Link style={{color: colorPalette.lightLink}} href="https://www.flaticon.com/" title="Flaticon">www.flaticon.com</Link>.   
        </Typography>
      </footer>
      </div>
  );
}

MainLayout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default MainLayout
